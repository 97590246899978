// brand colors
$black: rgb(17, 17, 17);
$grey: rgb(62, 66, 68);
$greyDark: rgb(34, 34, 34);
$greyLight: rgb(238, 238, 238);
$red: rgb(168, 41, 46);
$blue: #255edd;
$white: rgb(255, 255, 255);
// $whiteOff: rgb(247, 247, 247);
$whiteOff: #eaeaea;

// easings
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
