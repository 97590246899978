// global
@import './reset.scss';
@import './vars.scss';

body {
  background-color: $whiteOff;
  color: $black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 0 1.25rem;
  overflow-x: hidden;

  font-smooth: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  > * {
    padding: 2rem 0;
  }

  * {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    text-shadow: 1px 1px 1px rgba($black, 0.004);
    text-rendering: optimizeLegibility;
  }
}

h1, h2, h3, h4, h5, h6 {
  letter-spacing: -.05rem;
}

h1 {
  color: $greyDark;
  // font-weight: 500;
  margin-bottom: 1rem;
}

h2, h3 {
  color: $grey;
}

h2, h3, h4 {
  font-weight: 500;
}

h2 {
  margin: 1.25rem 0;
}

h3, h4 {
  margin-bottom: 1.25rem;
}

h3 {
  font-size: 1.35rem;
}

h4 {
  font-size: 1.25rem;
}

header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

a.logo {
  line-height: 0;

  img {
    height: 2.5rem;
    width: 2.5rem;
  }
}

nav {
  ul {
    background-image: linear-gradient(to top, rgba($whiteOff, 0) 0%, rgba($whiteOff, 0.9) 20%, rgba($whiteOff, 1) 100%);
    font-size: 1.25rem;
    opacity: 0;
    padding-bottom: 0;
    padding-top: 1rem;
    position: absolute;
    right: 1.25rem;
    text-align: right;
    top: -100vh;
    width: 100%;

    li {
      margin: 0 0 1rem 1rem;
    }
  }
}

main {
  p,
  ul {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 2.2rem;
    margin-bottom: 1.5rem;
  }

  ul {
    margin: 0 0 1.5rem 1.5rem;
  }

  li {
    font-size: 1.5rem;
    font-weight: 300;

    &::before {
      content: "\2022";
      margin: 0 0 0 -1.5rem;
      position: absolute;
      font-size: 2.5rem;
    }
  }

  em,
  em * {
    font-weight: 300;
  }

  section.content {
    padding-bottom: 1.5rem;

    p,
    div,
    li {
      font-size: 1.15rem;
      font-weight: 400;
    }

    p,
    ul {
      color: $greyDark;
      line-height: 1.75rem;
    }

    ul {
      padding-left: 1rem;

      li {
        &::before {
          content: '\25AA';
          color: $grey;
          font-size: 1.35rem;
          margin-left: -1rem;
          padding-right: 0.5rem;
        }
      }
    }
  }
}

nav {
  display: flex;
  flex-direction: row;

  &.open {
    ul {
      opacity: 1;
      padding-bottom: 12rem;
      top: 5rem;
      transition: opacity 0.35s $easeInOutSine, top 0.35s $easeOutSine 0s, padding-bottom 0.35s $easeOutSine;
    }

    img {
      // animation-direction: normal;
      // animation-iteration-count: 1;
      // animation-play-state: running;
    }
  }

  img {
    // animation-name: spin;
    // animation-direction: normal;
    // animation-duration: 500ms;
    // animation-iteration-count: 0;
    // animation-play-state: paused;
    // animation-timing-function: $easeOutSine;
    cursor: pointer;
  }

  &.closed {
    ul {
      opacity: 0;
      padding-bottom: 0;
      top: -100vh;
      transition: opacity 0.5s $easeInOutSine, top 0.5s $easeInOutSine 0.2s, padding-bottom 0.5s $easeInOutSine;
    }

    img {
      // animation-direction: reverse;
      // animation-iteration-count: 1;
      // animation-play-state: running;
    }
  }
}

footer p {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  text-align: right;

  a.social {
    margin-left: 1rem;

    img {
      height: 1.5rem;
      width: 1.5rem;
    }
  }

  small {
    color: lighten($grey, 10%);
    margin-top: 1rem;
    width: 100%;
  }
}

a {
  color: $red;
  border-bottom: 1px dashed lighten($grey, 20%);
  padding-bottom: 0.05rem;
  text-decoration: none;
  // transition: transform 0.2s ease-in-out;

  abbr {
    text-decoration: none;
  }

  &:hover {
    // text-decoration: underline;
    border-bottom: 2px solid $red;
    // transform: scale(1.2);
  }
}

strong {
  font-weight: 700;
}

a.icon {
  border: 0;
  // border: 1px dashed $grey;
  // border-radius: 2em;
  padding: 3px;
  transition: transform 0.2s ease-in-out;

  &:hover {
    // border: 0;
    transform: scale(1.2);
  }
}

.arrow-link {
  display: inline-block;

  .arrow {
    background-image: url('/assets/arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    padding-left: 1.75rem;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg) scale(1);
  }

  50% {
    transform: rotate(180deg) scale(1.2);
  }

  100% {
    transform: rotate(360deg) scale(1);
  }
}

.domain {
  display: none;
}

.bio {
  // color: $grey;
  font-size: 1.75rem;
  font-weight: 300;
  line-height: 2.35rem;
}

hr {
  border: 0;
  border-bottom: 4px solid lighten($grey, 40%);
  margin: 2rem 0;

  &.light {
    border-bottom: 2px solid lighten($grey, 55%);
  }
}

.cta,
.cta a {
  font-size: 1.75rem;
  font-weight: 500;
  padding-top: 1rem;
}

.greeting {
  font-size: 1.275rem;
  font-weight: 500;
  // font-weight: 500;
  // letter-spacing: .05rem;
  // margin-bottom: 1.25rem;
  // text-transform: uppercase;

  strong {
    color: $red;
    font-size: 1.75rem;
  }
}

.nobr {
  font-weight: inherit;
  white-space: nowrap;
}

.right {
  text-align: right;
}

.light {
  color: lighten($black, 40%);
  font-style: italic;
  font-weight: inherit;
}

.summary {
  color: lighten($black, 50%);
  display: inline-block;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 300;
  // padding-top: 1rem;
  text-transform: uppercase;

  &::before {
    color: lighten($black, 60%);
    content: "»";
    font-size: 1.75rem;
    // font-weight: 400;
    padding-right: 0.25rem;
  }
}

.conclusion {
  color: $red;
  font-size: 1.35rem;
  font-style: italic;
  line-height: 2rem;
}

.role {
  position: relative;
  padding-top: 1rem;
  overflow: hidden;
}

.backlight {
  font-size: 8rem;
  line-height: 10rem;
  font-weight: 500;
  letter-spacing: -0.075rem;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  position: absolute;
  top: -2rem;
  z-index: -1;
  background: linear-gradient(to right, lighten($black, 80%), rgba(255, 255, 255, 0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.signature {
  color: $grey;
  font-size: 1.35rem;
  font-style: italic;
  font-weight: 500;

  &::before {
    content: "\2014";
  }
}

// @todo breakpoints
@media only screen and (min-width: 768px) {
  html {
    font-size: 20px;
  }

  body {
    padding: 0 3rem;
  }

  nav {
    img {
      display: none;
    }

    ul {
      background-image: none;
      display: flex;
      font-size: 1.15rem;
      opacity: 1;
      padding: 0;
      position: relative;
      right: auto;
      top: auto;
      width: auto;

      li {
        margin: 0 0 0 1.5rem;

        a {
          padding-bottom: 0.25rem;
        }
      }
    }

    &.closed ul {
      opacity: 1;
      top: auto;
    }
  }

  main {
    margin: 0 auto;
    max-width: 32rem;
  }

  // body:not(.home) {
  //   h1 {
  //     font-size: 4rem;
  //     margin-bottom: 3rem;
  //     text-align: center;
  //   }
  // }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.65rem;
  }

  h4 {
    font-size: 1.35rem;
  }
}

@media only screen and (min-width: 960px) {
  main {
    max-width: 40rem;
  }
}

@media only screen and (min-width: 1200px) {
  header {
    position: fixed;

    a.logo img {
      height: 2.75rem;
      width: 2.75rem;
    }
  }

  main {
    margin-top: 7rem;
  }
}

@media only screen and (min-width: 1440px) {
  main {
    margin-bottom: 7rem;
  }

  footer {
    position: fixed;
    bottom: 0;
    right: 2rem;
  }
}
